import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Block from '../components/Block';
import Text from '../components/Text';
import Particles from '../components/Particles';

import Careers from '../structure/Careers';
import Footer from '../structure/Footer';

const Benefit = styled.div`
  margin-bottom: 10px;

  span { margin-right: 5px; }
`;

const CareersPage = () => (
  <StaticQuery
    query={graphql`
      query BenefitsQuery {
        file(relativePath: {regex: "/benefits/"}) {
          childMarkdownRemark {
            frontmatter {
              benefit {
                icon
                name
              }
            }
          }
        }
      }      
    `}
    render={({ file }) => (
      <Layout>
        <Particles height="100%" />
        <Block alignItems="center" px={3}>
          <Text
            is="h1"
            fontSize={[9, 10]}
            mt={[7, 8]}
            fontWeight="heavy"
          >
            Careers
          </Text>
          <Text
            maxWidth="1000px"
            is="p"
            fontSize={[1, 2]}
            mb={[4, 5]}
            fontWeight="light"
            textAlign="center"
          >
            We are always looking for awesome people!<br />
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Here's what you can expect by joining TheHive™:
          </Text>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          <Text
            width="100%"
            maxWidth="1200px"
            is="p"
            fontSize={[1, 2]}
            pl={[0, 5, 7]}
            py={4}
            fontWeight="light"
          >
            {file.childMarkdownRemark.frontmatter.benefit.map(benefit => (
              <Benefit key={benefit.name}>
                <span>{benefit.icon}</span> {benefit.name}
                <br />
              </Benefit>
            ))}
          </Text>
        </Block>
        <Careers />
        <Footer simplified />
      </Layout>
    )}
  />
);

export default CareersPage;
