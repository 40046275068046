/**
 * Careers
 * -----
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Sitewidth from '../components/Sitewidth';
import Post from '../components/Post';

const Careers = () => (
  <StaticQuery
    query={graphql`
      query CareersQuery {
        careers: allMarkdownRemark(
          filter: {fileAbsolutePath: {regex: "/careers/"}}
          sort: { fields: [frontmatter___date], order: ASC }
        ) {
          edges {
            node {
              id
              frontmatter {
                path
                title
                tags
                link {
                  text
                  alt
                }
                short
              }
            }
          }
        }
      }
    `}
    render={({ careers }) => (
      <Sitewidth>
        {careers.edges.map(({ node }) => (
          <Post
            key={node.id}
            inner
            title={node.frontmatter.title}
            content={node.frontmatter.short}
            tags={node.frontmatter.tags}
            link={{
              to: node.frontmatter.path,
              ...node.frontmatter.link,
            }}
          />
        ))}
      </Sitewidth>
    )}
  />
);

export default Careers;
