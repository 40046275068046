import React from 'react';

import styled from 'styled-components';
import { themeGet } from 'styled-system';

import Block from '../components/Block';
import Text from '../components/Text';
import Link from '../components/Link';

import { noJitter, position, ease } from '../utils';

const Feature = props => (
  <Block
    className={props.className}
    transform={props.transform}

    mb={6}
    mr={[3, 4, 5]}
    maxWidth={[null, null, '28%', '30%', '230px']}
  >
    <a
      onMouseEnter={props.onMouseEnter}
      href={props.href}
      target="_blank"
    >
      <div className="hover">
        <Text is="h3" fontSize={[5, 6]} mb={[2, 3]} fontWeight="bold">
          {props.title}
        </Text>
        <Text is="p" fontSize={[1, 2]} mb={4} fontWeight="light">
          {props.children}
        </Text>
        <Link
          is="div"
          href={props.href}
          target="_blank"
          alt={props.pun}
          fontSize={[2, 3]}
        >Read More
        </Link>
      </div>
    </a>
  </Block>
);

const StyledFeature = styled(Feature)`
  ${noJitter};
  z-index: 2000;

  @media (min-width: ${themeGet('breakpoints.3')}) {
    transform: ${props => props.transform} scale(0.9);
  }

  &:last-child {
    margin-right: 0;
  }

  .hover {
    @media (min-width: ${themeGet('breakpoints.3')}) {
      cursor: pointer;
      transition: transform 1200ms ${ease('out', 'expo')};

      &:before {
        content: "";
        ${''}

        ${position('absolute', '-40px', '-30px')};

        border-radius: 10px;
        background: ${themeGet('colors.white.base')};
        box-shadow: 0 0 0 ${themeGet('colors.black.shade.4')};
        opacity: 0;
        transition:
          opacity 5000ms ${ease('out', 'expo')},
          box-shadow 3000ms ${ease('out', 'expo')};
      }

      &:hover {
        transform: scale(1.1);

        &:before {
          opacity: 1;
          box-shadow: 0 0 50px ${themeGet('colors.black.shade.4')};
        }
      }
    }
  }
`;

export default StyledFeature;
