/**
 * Post
 * ----
 */

import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';
import { themeGet } from 'styled-system';

import Block from '../components/Block';
import Text from '../components/Text';
import Divider from '../components/Divider';
import Tag from '../components/Tag';
import Link from '../components/Link';

const Post = props => (
  <Block className={props.className}>
    <Divider />
    <Text
      is="h2"
      fontSize={[7, 8]}
      mb={[4, 5]}
      fontWeight="bold"
      maxWidth="700px"
    >
      {props.title}
    </Text>
    <Text
      maxWidth="1000px"
      is="p"
      fontSize={[1, 2]}
      mb={[4, 5]}
      fontWeight="light"
    >
      {props.content}
    </Text>
    <Block
      className="meta"
      flexDirection={['column', null, 'row']}
      justifyContent="space-between"
    >
      <Block className="tags" flexDirection="row" flexWrap="wrap" width="auto">
        {props.tags.map(tag => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </Block>
      {props.link
        ? <Link
          href={props.link.href}
          to={props.link.to}
          target="_blank"
          alt={props.link.alt}
          is={props.inner ? GatsbyLink : 'a'}
          align="right"
        >
          {props.link.text}
          </Link>
        : null
      }
    </Block>
  </Block>
);

Post.defaultProps = {
  tags: [],
};

const StyledPost = styled(Post)`
  .tags {
    margin-left: -5px;
  }

  @media (max-width: ${themeGet('breakpoints.2')}) {
    ${Link} {
      margin-top: 20px;

      div:nth-child(2) {
        right: auto !important;
        left: 0;
      }
    }
  }
`;

export default StyledPost;
