/**
 * Link
 * ----
 */

import React from 'react';
import { isMobile } from 'react-device-detect';

import styled, { css } from 'styled-components';
import { themeGet, fontSize } from 'styled-system';
import themed from 'styled-theming';

import Feature from './Feature';

import arrow from '../assets/icons/arrow.svg';

import { rem, ease } from '../utils';

/**
 * Config
 * ------
 */

const conf = {
  font: {
    size: rem(18),
    weight: themeGet('fontWeights.regular'),
  },

  color: themed('mode', {
    dark: themeGet('colors.brand.base'),
    light: themeGet('colors.brand.base'),
    color: themeGet('colors.black.shade.0'),
  }),

  transition: {
    duration: 250,
  },
};

const isEmoji = args => (
  args.match(/([\uD800-\uDBFF][\uDC00-\uDFFF])/g) ? true : false
);

const renderWithEmoji = args => (
  <div>
    {args.replace(/[ ]([\uD800-\uDBFF][\uDC00-\uDFFF])/g, '')}
    <span className="emoji">
      {args.match(/([\uD800-\uDBFF][\uDC00-\uDFFF])/gm)}
    </span>
  </div>
);

const Link = props => (
  <props.is
    className={props.className}
    href={props.href}
    target={props.target}
    to={props.to}
  >
    <div>{props.children}</div>
    {props.alt &&
      isEmoji(props.alt)
      ? renderWithEmoji(props.alt)
      : <div>{props.alt}</div>
    }
  </props.is>
);

Link.defaultProps = {
  is: 'a',
};

const StyledLink = styled(Link)`
  position: relative;
  cursor: pointer;

  div {
    ${fontSize};

    display: inline-block;
    pointer-events: none;

    font-weight: ${conf.font.weight};
    color: ${conf.color};

    transition: 600ms opacity, transform 1000ms ${ease('out', 'circ')};

    &:after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-left: 8px;
      background: url(${arrow}) no-repeat center;
      background-size: contain;
    }

    &:nth-of-type(2) {
      ${isMobile
        ? css`
          display: block;
          font-size: 80%;
          margin-top: 8px;
          opacity: 0.5;

          &:before {
            content: '/ ';
          }

          &:after {
            display: none;
          }
        `

        : css`
          position: absolute;
          top: 0;
          ${props => (props.align === 'right' ? 'right: 0;' : 'left: 0;')}
          white-space: nowrap;
          opacity: 0;
          transform: translateY(100%);
        `
      }
    }

    .emoji {
      display: inline-block;
      line-height: 1.5;

      ${isMobile
        ? css`transform: translateY(0.5px); margin-left: 5px;`
        : css`transform: translateY(2px); margin-left: 7px;`
      }

    }
  }

  ${!isMobile && css`
    &:hover div:after,
    ${Feature}:hover & div:after {
      animation: link 700ms infinite;
      @keyframes link { 100% { transform: translateX(4px) } }
    }

    ${props => (props.alt && css`
      &:hover div,
      ${Feature}:hover & div {
        &:nth-of-type(1) {
          opacity: 0;
          transform: translateY(-100%);
        }

        &:nth-of-type(2) {
          opacity: 1;
          transform: translateY(0%);
        }
      }
    `)}
  `}
`;

StyledLink.defaultProps = {
  fontSize: conf.font.size,
};

export default StyledLink;
