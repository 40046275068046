/**
 * Divider
 * -------
 */

import styled from 'styled-components';
import { themeGet } from 'styled-system';

const Divider = styled.div`
  width: 100px;
  margin: 60px auto;
  border-bottom: 2px solid ${themeGet('colors.black.shade.4')};

  @media (min-width: ${themeGet('breakpoints.1')}) { margin: 100px auto; }
`;

export default Divider;
